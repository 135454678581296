export const properties = {
    googleKey: "AIzaSyA7b8RlEp1VkXA5rUrsfdckJ0IRzpzTH8A",
    // reCaptchaKey: "6Ldroa8dAAAAAOBY-djpViwXOC_VGpCAL4ztuqxN", // V3
    reCaptchaKey: "6LeFAtsdAAAAABXVSCKp1zgD2Hphg_qS0fFdRpxt", // V2

    //
    // DEV
    //
    //backendMail: "http://localhost:8888/send.php", // same folder
    backendMail: "http://localhost:8888/api/mail", // rmasrv
    baseUrl:"http://localhost:3000/", // TODO: Update with prod url
    assetUrl: "http://localhost:3000/assets/", // TODO: Update with prod url
    projectImagesUrl: "http://localhost:3000/assets/projects/", // TODO: Update with prod url
    imagesUrl: "http://localhost:3000/assets/img/",

    //
    // TEST
    //
    // backendMail: "https://rma.wrlab.be/send.php", // TODO: Update with prod url
    //backendMail: "http://rmasrv.wrlab.be/api/mail", // rmasrv
    // baseUrl:"https://rma.wrlab.be/", // TODO: Update with prod url
    // assetUrl: "https://rma.wrlab.be/assets/", // TODO: Update with prod url
    // projectImagesUrl: "https://rma.wrlab.be/assets/projects/", // TODO: Update with prod url
    // imagesUrl: "https://rma.wrlab.be/assets/img/",

    //
    // PROD
    //
    // backendMail: "https://XXX.XXX/send.php", // TODO: Update with prod url
    // baseUrl:"https://XXX.XXX/", // TODO: Update with prod url
    // assetUrl: "https://XXX.XXX/assets/", // TODO: Update with prod url
    // projectImagesUrl: "https://XXX.XXX/assets/projects/", // TODO: Update with prod url
};