import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'cookies',
  initialState: {
    cookieConsent: false,
  },
  reducers: {
    accept: state => {
      state.cookieConsent = true;
    },
    refuse: state => {
      state.cookieConsent = false;
    }
  },
});

export const { accept, refuse } = slice.actions;

export const getCookieConsent = state => state.cookies.cookieConsent;

export default slice.reducer;