import './Menu.css';

import React from "react";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { Logo } from '../components/Logo';
import { NavLink } from 'react-router-dom';

export default class Menu extends React.Component {
  render() {
    const { location } = this.props;

    return (
      <Navbar collapseOnSelect expand="md" fixed="top" bg="light">
        <Container className="container-fluid">
          <Navbar.Brand href="/">
            <Logo className="logo-menu" /> Romain Arnoly
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
            <Nav className="mr-auto" activeKey={location.pathname}>
              <Nav.Item>
                <Nav.Link eventKey={0}>
                  <NavLink exact to="/" className="nav-item nav-link" activeClassName="nav-item nav-link active">Accueil</NavLink>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={1}>
                  <NavLink to="/projects" isActive={() => ['/projects', '/project'].includes(location.pathname) || location.pathname.includes("/project/")} className="nav-item nav-link" activeClassName="nav-item nav-link active">Projets</NavLink>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={2}>
                  <NavLink to="/about" className="nav-item nav-link" activeClassName="nav-item nav-link active">L'équipe</NavLink>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={3}>
                  <NavLink to="/contact" className="nav-item nav-link" activeClassName="nav-item nav-link active">Contact</NavLink>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      );
    }
}