import './ProjectDetails.css';

import React from 'react';
import {Link} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Gallery from "react-photo-gallery";
import AbsoluteWrapperWithFooter, { DetailProjectWrapper } from '../components/AbsoluteWrapper';
import {animated, useSpring} from "react-spring";
import {projects, getProject} from '../components/ProjectsProvider';
import {Transition} from 'react-spring';
import { ReactCompareSlider } from 'react-compare-slider';

import Carousel, { Modal, ModalGateway } from "react-images";

const cont = {
    backgroundColor: "#eee",
    cursor: "pointer",
    overflow: "hidden",
    position: "relative"
  };
  
const GalleryImage = ({
    index,
    photo,
    margin,
    direction,
    top,
    left,
    onPress
    }) => {
    if (direction === "column") {
        cont.position = "absolute";
        cont.left = left;
        cont.top = top;
    }

    const [{ opacity, transform }, set] = useSpring(() => ({config: { duration: 350 }, opacity: 0, transform: "translate(-50px, 0px)" }));

    return (
        <div 
        style={{ margin, height: photo.height, width: photo.width, ...cont }} 
        className="gallery-image-container"
        onMouseEnter={() => set({ opacity: 1, transform: "translate(0px, 0px)"})}
        onMouseLeave={() => set({ opacity: 0, transform: "translate(-50px, 0px)" })}
        onClick={(e) => onPress(e, {photo:photo, index:index})}>
        <div className="gallery-image">
            <div className="details gallery-image-overlay">
                <div className="details gallery-image-caption">
                    <animated.h3 style={{opacity, transform}}><i className="bi bi-search"></i></animated.h3>
                </div>
            </div>
            
            <img
            alt={photo.title}
            {...photo}
            />
        </div>
        
        </div>
    );
};

class CompareSliderImageLabdel extends React.Component {
    render() {

        const labelClass = "image-compared-label " + (this.props.position !== undefined ? this.props.position : "left");
        return(
            <div className={labelClass}>{this.props.label}</div>
        );
    }
}

class CompareSliderImage extends React.Component {
    render() {
        const label = this.props.label;
        const position = this.props.labelPosition;
        return(
            <div className="image-compared-container">
                {
                    label !== undefined &&
                    <CompareSliderImageLabdel position={position} label={label} />
                }
                
                <img src={this.props.src} srcSet={this.props.srcSet} alt={this.props.alt} className="image-compared" />
            </div>
        );
    }
}

class ProjectDetailsImages extends React.Component {
    constructor(props) {
        super(props);

        this.state = { currentItem:0, isViewerOpen:false};
        this.openLightbox = this.openLightbox.bind(this);
        this.closeLightbox = this.closeLightbox.bind(this);
        this.imageRenderer = this.imageRenderer.bind(this);
    }

    openLightbox = (e, {photo, index}) => {
        this.setState({currentItem: index, isViewerOpen:true});
    }

    closeLightbox = () => {
        this.setState({currentItem: 0, isViewerOpen:false});
    }

    imageRenderer = ({ index, photo, margin, direction, top, left, key, onClick }) => {
        return (
            <GalleryImage
              key={key}
              margin={margin}
              index={index}
              photo={photo}
              left={left}
              top={top}
              direction={direction}
              onPress={onClick}
            />
        );
    }

    columns(containerWidth) {
        let columns = 1;
        if (containerWidth >= 500) columns = 3;
        return columns;
      }
      

    render() {

        const project = this.props.project;

        return(
            <div className="project-details-images">

                { 
                    project.compare !== undefined && 
                    <ReactCompareSlider className="images-comparer"
                        itemOne={<CompareSliderImage src={project.images[project.compare[0]].src} srcSet={project.images[project.compare[0]].srcSet} alt={project.images[project.compare[0]].alt} label="Avant" labelPosition="left" />}
                        itemTwo={<CompareSliderImage src={project.images[project.compare[1]].src} srcSet={project.images[project.compare[1]].srcSet} alt={project.images[project.compare[1]].alt} label="Après" labelPosition="right" />}
                    />
                }

                <Gallery photos={project.images} direction={"column"} columns={this.columns} margin={5} onClick={this.openLightbox} renderImage={this.imageRenderer} />

                <ModalGateway>
                    {this.state.isViewerOpen ? (
                    <Modal onClose={this.closeLightbox}>
                        <Carousel
                        currentIndex={this.state.currentItem}
                        views={project.images.map(x => ({
                            ...x,
                            srcset: x.srcSet,
                            caption: x.title
                        }))}
                        />
                    </Modal>
                    ) : null}
                </ModalGateway>
            </div>
        );
    }
}

function ProjectsControlLeftButton(props) {
    return (
        <OverlayTrigger
            placement='top'
            overlay={
                <Tooltip>
                    Afficher le projet précédent
                </Tooltip> }
        >
            <Button onClick={props.handleClick} className="projects-control-prev"><i className="bi bi-chevron-left"></i></Button>
        </OverlayTrigger>
    );
}  

function ProjectsControlRightButton(props) {
    return (
        <OverlayTrigger
            placement='top'
            overlay={
                <Tooltip>
                    Afficher le projet suivant
                </Tooltip> }
        >
            <Button onClick={props.handleClick} className="projects-control-next"><i className="bi bi-chevron-right"></i></Button>
        </OverlayTrigger>
        
    );
}

function ProjectsControlLeftSquareButton(props) {
    return (
        <Button onClick={props.handleClick} className="projects-control-prev square"><i className="bi bi-chevron-left"></i></Button>
    );
}  

function ProjectsControlRightSquareButton(props) {
    return (
        <Button onClick={props.handleClick} className="projects-control-next square"><i className="bi bi-chevron-right"></i></Button>
    );
}

class ProjectDetailsDescription extends React.Component {

    render() {
        const project = this.props.project;
        const displayDescription = project.title !== undefined && project.title !== null && project.title !== "";

        console.log(displayDescription);

        return(
            <div className={`sticky-lg-top sticky-offset ${displayDescription ? "project-details-description" : ""}`}>
                <Row>
                    <Col xs={3} className="d-block d-sm-none align-self-center center">
                        <ProjectsControlLeftSquareButton handleClick={this.props.handlePrevious} />
                    </Col>
                    
                    <Col xs={6} sm={12} className="align-self-center center">
                        <h3>{project.title}</h3>
                        { displayDescription === true && <div className="title-heading-line"></div> }
                    </Col>
                    
                    <Col xs={3} className="d-block d-sm-none  align-self-center center">
                        <ProjectsControlRightSquareButton handleClick={this.props.handleNext} />
                    </Col>
                </Row>
                {
                   displayDescription === true &&
                    <Row>
                        <Col xs={12}>
                            <div className="d-flex project-description-line">
                                <span className="project-description-subtitle">Programme : </span> 
                                <span className="project-description-content">{project.program ?? "—"}</span> 
                            </div>
                            <div className="d-flex project-description-line">
                                <span className="project-description-subtitle">Année : </span>
                                <span className="project-description-content">{project.year ?? "—"}</span> 
                            </div>
                            <div className="d-flex project-description-line">
                                <span className="project-description-subtitle">Lieux : </span> 
                                <span className="project-description-content">{project.place ?? "—"}</span> 
                            </div>
                            <div className="d-flex project-description-line">
                                <span className="project-description-subtitle">Maître d'ouvrage : </span>
                                <span className="project-description-content">{project.owner ?? "—"}</span> 
                            </div>
                            <div className="d-flex project-description-line">
                                <span className="project-description-subtitle">Statut : </span> 
                                <span className="project-description-content">{project.status ?? "—"}</span> 
                            </div>
                            <div className="d-flex project-description-line">
                                <span className="project-description-subtitle">Partenaire stabilité : </span> 
                                <span className="project-description-content">{project.stabilityPartner ?? "—"}</span> 
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className="project-description-subtitle" >Description : </div>
                            <p>{project.description}</p>
                        </Col>
                    </Row>
                }
            </div>
        );
    }
}

class ProjectDetails extends React.Component {
    render() {

        const hideDescription = this.props.project.title === undefined || this.props.project.title === null || this.props.project.title.trim() === "";
        const imagesCols = hideDescription === true ? 12 : 8;

        return (
        <Row>
            {/* SM button */}
            <Col sm={2} className="d-none d-sm-block d-lg-none align-self-center center">
                <ProjectsControlLeftButton handleClick={this.props.handlePrevious} />
            </Col>
            
            {/* Description display only if data available */}
            <Col xs={12} sm={8} lg={4} className={hideDescription ? "d-sm-block d-lg-none" : ""}>
                <ProjectDetailsDescription project={this.props.project} handleNext={this.props.handleNext} handlePrevious={this.props.handlePrevious} />
            </Col>
                
            {/* SM button */}
            <Col sm={2} className="d-none d-sm-block d-lg-none align-self-center center">
                <ProjectsControlRightButton handleClick={this.props.handleNext} />
            </Col>
            
            {/* Images */}
            <Col xs={12} lg={imagesCols}>
                <ProjectDetailsImages project={this.props.project} />
            </Col>
        </Row>
    )};
}


class AnimatedProjectDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = { index:props.projectIndex, direction:0 };

        this.handleNext = this.handleNext.bind(this);
        this.handlePrevious = this.handlePrevious.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    
    handleNext() {
        this.handleChange(this.state.index+1, 0);
    }

    handlePrevious() {
        this.handleChange(this.state.index-1, 1);
    }

    handleChange(newIndex, direction) {
        if (newIndex >= projects.length) {
            newIndex = 0;
        }
        else if (newIndex < 0) {
            newIndex = projects.length-1;        
        }

        this.setState({index: newIndex, direction:direction});
    }

    render() {
        const reverse = this.state.direction === 1;
        const projectIndex =  this.state.index;
        
        return (
              <Transition
                items={projectIndex}
                from={{ opacity: 0, transform: reverse ? "translate(-100%,0)" : "translate(100%, 0)" }}
                enter={{ opacity: 1,  transform: "translate(0%, 0)" }}
                leave={{ opacity: 0, transform: reverse ? "translate(50%,0)" :"translate(-50%, 0)" }}
              >
                {(styles, item) => {

                  const project = getProject(item);
                  return (
                  <AbsoluteWrapperWithFooter>
                      <Container>
                          <Row>
                              {/* LG button */}
                              <Col lg={1} className="d-none d-lg-block center">
                                  <div className="projects-control-container sticky-lg-top sticky-offset">
                                      <ProjectsControlLeftButton handleClick={this.handlePrevious} />
                                  </div>
                              </Col>
                              
                              <Col xs={12} lg={10}>
                                  <animated.div style={styles}>
                                      <ProjectDetails project={project} handleNext={this.handleNext} handlePrevious={this.handlePrevious} />
                                  </animated.div>
                              </Col>
      
                              {/* LG button */}
                              <Col lg={1} className="d-none d-lg-block center">
                                  <div className="projects-control-container sticky-lg-top sticky-offset">
                                      <ProjectsControlRightButton handleClick={this.handleNext} />
                                  </div>
                              </Col>
                          </Row>
                      </Container>
                  </AbsoluteWrapperWithFooter>
                )}}
              </Transition>
        );
    }
};

export default class ProjectDetailsContainer extends React.Component {
   
    render() {
        let projectIndex = this.props.match.params.id;
        if (projectIndex !== undefined && !isNaN(projectIndex)) {
            projectIndex = projectIndex-1;
            if (projectIndex < 0 || projectIndex-1 > (projects.length-1))
                projectIndex = 0;
        }
        
        return(
            <DetailProjectWrapper>

                <Container>
                    <Breadcrumb>
                        <Breadcrumb.Item><Link to="/projects">Projets</Link></Breadcrumb.Item>
                        <Breadcrumb.Item active>Détails</Breadcrumb.Item>
                    </Breadcrumb>
                </Container>

                <AnimatedProjectDetails projectIndex={projectIndex} />

            </DetailProjectWrapper>
        );
    }
}