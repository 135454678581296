import "./AbsoluteWrapper.css";
import React from "react";
import Footer from "./Footer";

const AbsoluteWrapperWithFooter = ({ children }) => {
  return (
    <AbsoluteWrapper>
      <div className="content">
        {children}
      </div>

      <Footer />
    </AbsoluteWrapper>
  );
};

export const DetailProjectWrapper =  ({ children }) => {
  return <div className="project-details-wrapper position-absolute w-100">{children}</div>;
};

export const AbsoluteWrapper = ({ children }) => {
  return <div className="absolute-wrapper position-absolute w-100">{children}</div>;
};

export default AbsoluteWrapperWithFooter;
