import './Home.css';
import React from 'react';
import { animated, useSpring } from "react-spring";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AnimatedLogo } from '../components/Logo';

function AnimatedText(props) {
  const delay = props.delay ? 3000 + props.delay : 3000;

  const spring = useSpring({config: { duration: 1000 }, delay: delay, opacity: 1, cursor:'pointer', visibility:'visible', from: { opacity: 0, cursor: 'auto', visibility:'hidden' } });
  return (
    <animated.p style={spring}>{props.children}</animated.p>
  );
}

export default class Home extends React.Component {
  

    render() {
      
      return (
        <Container className="main-container">
          <Row className="main-row">
              <Col className="col-md align-self-center center d-none d-md-block d-lg-block">
                  <AnimatedLogo className="animatedLogo" />
                  <br />
                  <AnimatedText>Bientôt disponible</AnimatedText>
              </Col>
              <Col className="center d-block d-sm-block d-md-none d-lg-none">
                  <AnimatedLogo className="animatedLogo" />
                  <AnimatedText>Bientôt disponible</AnimatedText>
              </Col>
          </Row>
        </Container>
     ); 
    }
  }