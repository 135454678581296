import './Projects.css';
import React, {useCallback} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from '../components/Header';
import AbsoluteWrapperWithFooter from '../components/AbsoluteWrapper';
import Gallery from "react-photo-gallery";
import { animated, useSpring } from "react-spring";
import { Link } from "react-router-dom";
import {projectSummaryImages} from '../components/ProjectsProvider';

const cont = {
  backgroundColor: "#eee",
  cursor: "pointer",
  overflow: "hidden",
  position: "relative"
};

const GalleryImage = ({
  index,
  photo,
  margin,
  direction,
  top,
  left
}) => {
  if (direction === "column") {
    cont.position = "absolute";
    cont.left = left;
    cont.top = top;
  }

  const [{ opacity, transform }, set] = useSpring(() => ({config: { duration: 350 }, opacity: 0, transform: "translate(-50px, 0px)" }));

  return (
    <Link to={"/project/" + photo.projectIndex}>
      <div 
        style={{ margin, height: photo.height, width: photo.width, ...cont }} 
        className="gallery-image-container"
        onMouseEnter={() => set({ opacity: 1, transform: "translate(0px, 0px)"})}
        onMouseLeave={() => set({ opacity: 0, transform: "translate(-50px, 0px)" })}>
        <div className="gallery-image">
          <div className="gallery-image-overlay">
            <div className="gallery-image-caption">
              <animated.h3 style={{opacity, transform}}>{photo.alt}</animated.h3>
              <animated.p style={{opacity, transform}}>Voir le détails</animated.p>
            </div>
          </div>
          
          <img
            alt={photo.title}
            {...photo}
          />
        </div>
        
      </div>
    </Link>
  );
};

function ExposeGallery(props) {

  const imageRenderer = useCallback(
    ({ index, photo, margin, direction, top, left, key }) => (
      <GalleryImage
        key={key}
        margin={margin}
        index={index}
        photo={photo}
        left={left}
        top={top}
        direction={direction}
      />
    ), []
  );

  return(
    <Gallery photos={props.photos} renderImage={imageRenderer} direction={"column"} margin={10} {...props} />
  );
}

export default class Projects extends React.Component {
  constructor(props) {
    super(props);

    this.state = { open:false };
    this.openLightbox = this.openLightbox.bind(this);
    this.closeLightbox = this.closeLightbox.bind(this);
  }

  openLightbox() {
    this.setState({ open:true });
  }

  closeLightbox() {
    this.setState({ open:false });
  }

  componentWillUnmount() {
    window.scrollTo(0, 0);
  }

   render() {

    const projectImages = projectSummaryImages();

      return (
        <AbsoluteWrapperWithFooter>
          <Header title='Projets' />

          <Container>
            <Row>
              <Col>
                <ExposeGallery photos={projectImages} />
              </Col>
            </Row>
          </Container>
        </AbsoluteWrapperWithFooter>
     ); 
   }
  }
  