import "./Footer.css";
import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CookieManager } from '../features/cookies/CookieManager'
import { Link } from "react-router-dom";

export default class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = { openCookieManagementModal:false };
    }

    toggleCookieModal = (isOpen) => {
        this.setState({ openCookieManagementModal:isOpen });
    }

    acceptCookie = () => {
        this.toggleCookieModal(false);
    }

    declineCookie = () => {
        this.toggleCookieModal(false);
    }

    render() {
        const openCookieManagementModal =  this.state.openCookieManagementModal;
        const active = window.location.pathname === "/legal";

        return (
            <footer className="footer py-3 bg-light">
                <Container>
                    <Row className="align-items-center">
                        <Col md={4}>
                            <span><span className="copyright">©</span> 2021 Romain Arnoldy</span>
                        </Col>
                        <Col md={4} className="legal-links">
                            <Link className={`link ${active ? "active" : ""}`} to={"/legal"}>Mentions légales</Link> 
                            <span className="link-separator"> - </span>
                            <button className="link" onClick={() => this.toggleCookieModal(true)}>Gérer les cookies</button>
                        </Col>
                        <Col md={4}>
                            <span className="created-by-label">Réalisé par <a href="https://wrlab.be/" target="_blank" rel="noreferrer">Antoine Wislet</a></span>
                        </Col>
                    </Row>
                </Container>

                <CookieManager 
                    show={openCookieManagementModal} 
                    open={() => this.toggleCookieModal(true)} 
                    hide={() => this.toggleCookieModal(false)} 
                    acceptCookie={this.acceptCookie} 
                    declineCookie={this.declineCookie} 
                />
            </footer>
        );
    }
}