import './Modal.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export function MyModal (props) {
    const showHideClassName = props.show ? "modal display-block" : "modal display-none";
  
    return (
      <div className={showHideClassName}>
        <section className="modal-main">
          {props.children}
          
          <button type="button" onClick={props.handleClose}>
            Fermer
          </button>
        </section>
      </div>
    );
  };

  export function VerticallyCenteredModal(props) {

    const displayHeaderCloseBtn = props.hideHeaderCloseBtn === undefined;
    const displayHeader = props.title !== undefined;
    const displayAcceptBtn = props.acceptLabel !== undefined;
    const displayDeclineBtn = props.declineLabel !== undefined;
    const displayCloseBtn = props.hideCloseBtn === undefined;
    const displayMoreBtn = props.moreLabel !== undefined;
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* Optional Header */}
        { displayHeader && 
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              {props.title}
            </Modal.Title>
            {displayHeaderCloseBtn && <button type="button" class="btn-close" aria-label="Close" onClick={props.onHide}></button>}
          </Modal.Header> 
        }

        <Modal.Body>
          {props.children}
        </Modal.Body>
        <Modal.Footer>
          { displayMoreBtn && <Button onClick={props.onMore} className="me-auto">{props.moreLabel}</Button>}
          { displayCloseBtn && <Button onClick={props.onHide}>{props.closeLabel ?? "Fermer"}</Button>}
          { displayDeclineBtn && <Button onClick={props.onDecline}>{props.declineLabel}</Button>}
          { displayAcceptBtn && <Button onClick={props.onAccept} className="btn-cookie-proposal">{props.acceptLabel}</Button>}
        </Modal.Footer>
      </Modal>
    );
  }