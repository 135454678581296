import React from "react";
import { animated, useSpring } from "react-spring";

import './HomeButton.css';

function ProjectLogoItem() {
  return (
    <g transform="translate(7 23) scale(0.75)">
        <path
          fill="#000"
          stroke="none"
          strokeWidth="1"
          d="M101.27 23.7c4.58 1 9 2 13.4 3a1.61 1.61 0 011.5 1.87v11.12c0 2-.05 2-2.06 2H13c-1.65 0-1.73-.09-1.73-1.67V28.51a2.34 2.34 0 01.89-2q10-8.6 19.85-17.23a2.42 2.42 0 012.38-.58q16.41 3.72 32.83 7.39a3.72 3.72 0 002.11-.22c2.71-1.18 5.35-2.53 8.07-3.69a4.25 4.25 0 012.48-.29c6.66 1.49 13.3 3.08 20 4.61a1.61 1.61 0 011.49 1.88c-.17 1.75-.1 3.49-.1 5.32zM67.5 39.86V17.79l-33.27-7.44v29.51m45.62.14h19.81V18.33l-19.81-4.51zm-66.8 0h19.19V11.51a1.82 1.82 0 00-.43.19q-9.16 7.89-18.3 15.81a1.49 1.49 0 00-.44 1c-.07 3.79-.02 7.58-.02 11.49zM78 14c-2.7 1.16-5.2 2.26-7.73 3.29a1.5 1.5 0 00-1.1 1.71v21H78zm23.32 26h12.77V28.27l-12.77-2.76z"
        />
    </g>
  );
} 

function AboutLogoItem() {
  return (
    //   <path transform="translate(24 -27)
    //                   scale(0.9)"
    //     fill="#000"
    //     stroke="none"
    //     strokeWidth="0.7"
    //     d="M69.05 32H8.631C3.871 32 0 35.87 0 40.631v43.157c0 4.76 3.87 8.631 8.631 8.631h60.42c4.76 0 8.63-3.87 8.63-8.631V40.63c0-4.76-3.87-8.631-8.63-8.631zm4.316 51.788a4.328 4.328 0 01-4.316 4.315H8.631a4.328 4.328 0 01-4.315-4.315V40.63a4.328 4.328 0 014.315-4.315h60.42a4.328 4.328 0 014.315 4.315v43.157zm-9.71-17.263H48.55c-.593 0-1.079.486-1.079 1.079v2.158c0 .593.486 1.079 1.079 1.079h15.105c.593 0 1.079-.486 1.079-1.08v-2.157c0-.593-.486-1.079-1.08-1.079zm0-8.631H48.55c-.593 0-1.079.485-1.079 1.079v2.158c0 .593.486 1.078 1.079 1.078h15.105c.593 0 1.079-.485 1.079-1.078v-2.158c0-.594-.486-1.08-1.08-1.08zm0-8.631H48.55c-.593 0-1.079.485-1.079 1.078V52.5c0 .594.486 1.08 1.079 1.08h15.105c.593 0 1.079-.486 1.079-1.08v-2.158c0-.593-.486-1.078-1.08-1.078zM28.052 66.525c5.96 0 10.789-4.828 10.789-10.789 0-5.961-4.828-10.79-10.79-10.79-5.96 0-10.788 4.829-10.788 10.79 0 5.961 4.828 10.79 10.789 10.79zm0-17.262c3.574 0 6.473 2.9 6.473 6.473 0 3.574-2.9 6.473-6.473 6.473a6.475 6.475 0 01-6.474-6.473c0-3.574 2.9-6.473 6.474-6.473zm6.311 19.42c-2.63 0-3.29.944-6.311.944s-3.682-.944-6.312-.944c-2.86 0-5.637 1.268-7.256 3.695a9.027 9.027 0 00-1.537 5.058v3.115c0 .593.485 1.079 1.079 1.079h2.158c.593 0 1.079-.486 1.079-1.079v-3.115c0-.944.283-1.862.809-2.644.755-1.12 2.13-1.78 3.682-1.78 1.672 0 2.805.944 6.311.944 3.493 0 4.626-.944 6.312-.944 1.55 0 2.926.674 3.682 1.78.526.782.809 1.7.809 2.644v3.115c0 .593.485 1.079 1.079 1.079h2.157c.594 0 1.08-.486 1.08-1.079v-3.115a9.027 9.027 0 00-1.538-5.058c-1.659-2.427-4.437-3.695-7.283-3.695z"
    // ></path>
    <g transform="translate(33 18)
                   scale(3)"
      >
      <path d="M11 6a3 3 0 11-6 0 3 3 0 016 0z"></path>
      <path
        fillRule="evenodd"
        d="M0 8a8 8 0 1116 0A8 8 0 010 8zm8-7a7 7 0 00-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 008 1z"
      ></path>
    </g>
    
  );
} 

function ContactLogoItem() {
  return (
    // <g transform="translate(33 6)
    //               scale(3)">
    //   <path d="M2.678 11.894a1 1 0 01.287.801 10.97 10.97 0 01-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 01.71-.074A8.06 8.06 0 008 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 01-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 00.244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 01-2.347-.306c-.52.263-1.639.742-3.468 1.105z"></path>
    //   <path d="M4 5.5a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5zM4 8a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7A.5.5 0 014 8zm0 2.5a.5.5 0 01.5-.5h4a.5.5 0 010 1h-4a.5.5 0 01-.5-.5z"></path>
    // </g>

    <g>
      <g transform="translate(47 17)
                   scale(2)">
       <path d="M2.678 11.894a1 1 0 01.287.801 10.97 10.97 0 01-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 01.71-.074A8.06 8.06 0 008 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 01-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 00.244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 01-2.347-.306c-.52.263-1.639.742-3.468 1.105z"></path>
       <path d="M4 5.5a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5zM4 8a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7A.5.5 0 014 8zm0 2.5a.5.5 0 01.5-.5h4a.5.5 0 010 1h-4a.5.5 0 01-.5-.5z"></path>
     </g>
      <g transform="translate(33 33)
                   scale(2)">
        <path d="M3.654 1.328a.678.678 0 00-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 004.168 6.608 17.569 17.569 0 006.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 00-.063-1.015l-2.307-1.794a.678.678 0 00-.58-.122l-2.19.547a1.745 1.745 0 01-1.657-.459L5.482 8.062a1.745 1.745 0 01-.46-1.657l.548-2.19a.678.678 0 00-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 012.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 00.178.643l2.457 2.457a.678.678 0 00.644.178l2.189-.547a1.745 1.745 0 011.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 01-7.01-4.42 18.634 18.634 0 01-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"></path>
      </g>
    </g>
  );
} 

function CircleButtonLogoItem() {
  return (
    <g transform="translate(34.64 20)">
      <ellipse fill="#fff" 
          strokeWidth="1" 
          strokeDasharray="null" 
          strokeOpacity="null" 
          fillOpacity="null" 
          opacity="1" 
          cx="22.500000041961265" 
          cy="22.500000049868333" 
          id="svg_3" 
          rx="20.72599427346253" 
          ry="20.72599427346253" 
          stroke="#000" />
      <ellipse fill="#fff" 
          strokeWidth="1" 
          strokeDasharray="null" 
          strokeOpacity="null" 
          fillOpacity="null" 
          opacity="1" 
          cx="22.4999990507879" 
          cy="22.49999922174913" 
          id="svg_4" 
          rx="4.098358265992175" 
          ry="4.098358265992175" 
          // stroke="#000" 
          stroke="#c9b88a"/>
    </g>
  );
}

function BaseHomeButton(props) {
  return (
    <div className={props.className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        viewBox="0 0 115 85">
        <g>{props.children}</g>
      </svg>
      <span className="buttonLabel">
        {props.label}
      </span>
    </div>
  );
}

function AnimatedHomeButton(props) {
  const [isHoverMode, setHoverMode] = React.useState(false);

  const toggleHoverMode = () => {
    setHoverMode(previous => !previous);
  };

  const properties = {
    base: {
      circle: {
        opacity: 1,
        transform: 'scale(1)',
        transformOrigin: "center",
        transformBox: "fill-box"
      },
      icon: {
        opacity: 0,
        transform: 'scale(0)',
        transformOrigin: "center",
        transformBox: "fill-box"
      },
      label: {
        opacity: 0,
        transform: "translate(-20px, -40px)",
        transformOrigin: "center",
        transformBox: "fill-box"
      }
    },
    hover: {
      circle: {
        opacity: 0,
        transform: 'scale(2)',
        transformOrigin: "center",
        transformBox: "fill-box"
      },
      icon: {
        opacity: 1,
        transform: 'scale(1)',
        transformOrigin: "center",
        transformBox: "fill-box"
      },
      label: {
        opacity: 1,
        transform: "translate(0px, -20px)",
        transformOrigin: "center",
        transformBox: "fill-box"
      }
    },
    springConfig: { mass: 3, tension: 250, friction: 35 }
  };

  const { circle, icon, label } = properties[
    isHoverMode ? "hover" : "base"
  ];
  
  const iconProps = useSpring({ ...icon, config: properties.springConfig });
  const circleProps = useSpring({ ...circle, config: properties.springConfig });
  const labelProps = useSpring({ ...label, config: properties.springConfig });

  return (
    <div 
      className={props.className}
      onMouseEnter={toggleHoverMode}
      onMouseLeave={toggleHoverMode}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        viewBox="0 0 115 85">
        <animated.g style={circleProps}><CircleButtonLogoItem /></animated.g>
        <animated.g style={iconProps}>{props.children}</animated.g>
      </svg>
      <animated.span style={labelProps} className="buttonLabel">
        {props.label}
      </animated.span>
    </div>
  );
}

function HomeButton(props) {

  const useHoverAnimation = matchMedia('(hover: hover)').matches; // Device has a mouse
  if (useHoverAnimation) {
    return (<AnimatedHomeButton  {...props}>{props.children}</AnimatedHomeButton>);
  }
  else {
    return (<BaseHomeButton {...props}>{props.children}</BaseHomeButton>);
  }
}

export {HomeButton, ProjectLogoItem, AboutLogoItem, ContactLogoItem};