import './Home.css';
import React from 'react';
import { Link } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AnimatedLogo } from '../components/Logo';
import {HomeButton, ProjectLogoItem, AboutLogoItem, ContactLogoItem} from '../components/HomeButton';

function AnimatedLinkButton(props) {
  const AnimatedLink = animated(Link);
  const delay = props.delay ? 3000 + props.delay : 3000;

  const spring = useSpring({config: { duration: 1000 }, delay: delay, opacity: 1, cursor:'pointer', visibility:'visible', from: { opacity: 0, cursor: 'auto', visibility:'hidden' } });
  return (
    <AnimatedLink to={props.to} style={spring} className="animatedButtonLink"><HomeButton className={props.buttonClassName} label={props.label}>{props.children}</HomeButton></AnimatedLink>
  );
}

export default class Home extends React.Component {

    render() {
      return (
        <Container className="main-container">
          <Row className="main-row">
              <Col className="col-md align-self-center center d-none d-md-block d-lg-block">
                  <AnimatedLinkButton to="/projects" buttonClassName="animatedButton top" label="Projets"><ProjectLogoItem /></AnimatedLinkButton>
                  <AnimatedLogo className="animatedLogo" />
                  <AnimatedLinkButton to="/about" buttonClassName="animatedButton bottom-right" label="A propos"><AboutLogoItem /></AnimatedLinkButton>
                  <AnimatedLinkButton to="/contact" buttonClassName="animatedButton bottom-left" label="Contact"><ContactLogoItem /></AnimatedLinkButton>
              </Col>
              <Col className="center d-block d-sm-block d-md-none d-lg-none">
                  <AnimatedLogo className="animatedLogo" />
                  <AnimatedLinkButton to="/projects" buttonClassName="animatedButton-little" label="Projets"><ProjectLogoItem /></AnimatedLinkButton>
                  <AnimatedLinkButton to="/about" delay={150} buttonClassName="animatedButton-little" label="A propos"><AboutLogoItem /></AnimatedLinkButton>
                  <AnimatedLinkButton to="/contact" delay={300} buttonClassName="animatedButton-little" label="Contact"><ContactLogoItem /></AnimatedLinkButton>
              </Col>
          </Row>
        </Container>
     ); 
    }
  }