import React from 'react';
import { animated, useSpring } from "react-spring";

function BuildingLogo(props) {
    return (
        <g transform="translate(-175.235 -115.727)">
            <path
            fill="none"
            stroke="#000"
            strokeDashoffset={props.x ?? "819.3"}
            strokeDasharray="409.65"
            strokeMiterlimit="10"
            strokeWidth="2"
            d="M350.94 237.8L335.84 237.8 324.29 185.39 314.52 237.8 303.86 237.8 303.86 222.03 269.66 206.27 269.66 237.8 279.87 237.8 279.87 159.63 259.44 174.73 259.44 237.8 244.34 237.8"
             />
        </g>
    );
}

function CircleLogo(props) {
    return (
        <g transform="translate(-175.235 -115.727)">
            <ellipse
            fill={props.fill ?? "none"}
            stroke="#000"
            strokeDashoffset={props.x ?? "1501.42"}
            strokeDasharray="750.71"
            strokeMiterlimit="10"
            strokeWidth="1"
            rx="119.48"
            ry="119.48"
            transform="matrix(0 1.00003 1.00003 0 297.718 238.21)"
            />
        </g>
    );
}

function ArchiLogo(props) {
    return (
        <text opacity={props.opacity ?? 1}
            fill="#000"
            stroke="none"
            strokeWidth="1"
            dx="0"
            dy="0"
            fontFamily="CaviarDreams"
            fontSize="12.312"
            transform="translate(245.492 305.855)"
            >
            A R C H I T E C T E
        </text>
    );
}

function NameLogo(props) {
    return (
        <text
            fill="#000"
            stroke="none"
            strokeWidth="1"
            dx="0"
            dy="0"
            fontFamily="CaviarDreams"
            fontSize="21.889"
            opacity={props.opacity ?? 1}
            transform="translate(197.015 280.175)"
            >
            ROMAIN ARNOLDY
        </text>
    );
}

function Logo(props) {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="0 0 245 245"
      className={props.className}
      height={props.height}
      transform={props.transform}
    >
      <CircleLogo fill={props.circleFill ?? "none"} />
      <BuildingLogo />
      <g transform="translate(-175.235 -115.727)">
        <ArchiLogo />
        <NameLogo />
      </g>
    </svg>
  );
}

function AnimatedLogo(props) {
    const AnimatedCircle = animated(CircleLogo);
    const circleSpring = useSpring({ config: { duration: 3000 }, x: 0, from: { x: -750.71 } });

    const AnimatedBuilding = animated(BuildingLogo);
    const buildingSpring = useSpring({ config: { duration: 3000 }, x: 0, from: { x: -409.65 } });

    const AnimatedArchi = animated(ArchiLogo);
    const archiSpring = useSpring({ config: { duration: 1200 }, delay: 1800, opacity: 1, from: { opacity: 0 } });

    const AnimatedName = animated(NameLogo);
    const nameSpring = useSpring({ config: { duration: 1200 }, delay: 1800, opacity: 1, from: { opacity: 0 } });
    
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            shapeRendering="geometricPrecision"
            textRendering="geometricPrecision"
            viewBox="0 0 245 245"
            className={props.className}
        >
            <AnimatedBuilding x={buildingSpring.x} />
            <AnimatedCircle x={circleSpring.x} />
            <g transform="translate(-168 -115.727)">
                <AnimatedArchi opacity={archiSpring.opacity} />
                <AnimatedName  opacity={nameSpring.opacity} />
            </g>
        </svg>
    );
  }

export {Logo, AnimatedLogo };
