import './Header.css';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default class Header extends React.Component {
    render() {
        const title = this.props.title;
        const backgroundColor = this.props.backgroundColor !== "" ? this.props.backgroundColor : "white";
        const classes = this.props.forceDisplay ? "page-header" : "page-header d-block d-sm-block d-md-none";
        return (
            <section className="section-header"
                     style={{backgroundColor:backgroundColor}}>
                <Container>
                    <Row>
                        <Col className="col-12">
                            <div className={classes}>
                                <h4>{title}</h4>
                                <div className="page-header-line" ></div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}