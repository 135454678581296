import { properties } from "../properties";

export const projects = [

    //
    // PROJECT 1
    //
    {
        title: "",
        //title: "Projet Tilff",
        program: "Sample",
        year: "2021",
        place: "Tilff", 
        owner: "Jean Dupont",
        status: "En cours",
        stabilityPartner: "Stability Company",
        description: "Exercitation proident amet sit laboris dolore consectetur consectetur esse cupidatat do proident exercitation. Anim duis consectetur incididunt fugiat Lorem est cupidatat elit dolore. Cillum nostrud nulla veniam mollit minim ullamco est fugiat qui anim. Aute nisi culpa qui duis consequat aliquip nulla adipisicing labore. Dolor ipsum quis eu incididunt dolore nostrud consequat. Ullamco sint ex do tempor. Eu sit aute eiusmod sunt ex ut commodo ut quis pariatur sunt.",
        projectIndex: 1,
        images: [
            {
                src: properties.projectImagesUrl + "01/01/01-main-1_axeoye_c_scale,w_1452.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/01-main-1_axeoye_c_scale,w_480.jpg 480w",
                  properties.projectImagesUrl + "01/01/01-main-1_axeoye_c_scale,w_1452.jpg 1452w",
                  properties.projectImagesUrl + "01/01/01-main-1_axeoye_c_scale,w_2087.jpg 2087w",
                  properties.projectImagesUrl + "01/01/01-main-1_axeoye_c_scale,w_2612.jpg 2612w"
                ],
                sizes: ["(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"],
                width: 2612,
                height: 1846,
                alt:"Projet Tilff",
                projectIndex: 1,
                useInSummary: true
              },
              {
                src: properties.projectImagesUrl + "01/01/01-main-2_ipsmzg_c_scale,w_1268.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/01-main-2_ipsmzg_c_scale,w_480.jpg 480w",
                  properties.projectImagesUrl + "01/01/01-main-2_ipsmzg_c_scale,w_1268.jpg 1268w",
                  properties.projectImagesUrl + "01/01/01-main-2_ipsmzg_c_scale,w_1907.jpg 1907w",
                  properties.projectImagesUrl + "01/01/01-main-2_ipsmzg_c_scale,w_2280.jpg 2280w"
                ],
                sizes: ["(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"],
                width: 2280,
                height: 1612,
                alt:"Projet Tilff",
                projectIndex: 1,
                useInSummary: true
              },
              {
                src: properties.projectImagesUrl + "01/01/01-main-3_pfhxzl_c_scale,w_2069.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/01-main-3_pfhxzl_c_scale,w_480.jpg 480w",
                  properties.projectImagesUrl + "01/01/01-main-3_pfhxzl_c_scale,w_2069.jpg 2069w",
                  properties.projectImagesUrl + "01/01/01-main-3_pfhxzl_c_scale,w_2812.jpg 2812w",
                  properties.projectImagesUrl + "01/01/01-main-3_pfhxzl_c_scale,w_3840.jpg 3840w"
                ],
                sizes: ["(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"],
                width: 3840,
                height: 2563,
                alt:"Projet Tilff",
                projectIndex: 1,
                useInSummary: true
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_001-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_001-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_001-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_001-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 4000,
                height: 3000,
                alt: "Projet Tilff",
                projectIndex: 1
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_002-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_002-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_002-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_002-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 4000,
                height: 3000,
                alt: "Projet Tilff",
                projectIndex: 1
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_003-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_003-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_003-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_003-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 4000,
                height: 3000,
                alt: "Projet Tilff",
                projectIndex: 1
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_004-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_004-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_004-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_004-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 4000,
                height: 3000,
                alt: "Projet Tilff",
                projectIndex: 1
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_005-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_005-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_005-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_005-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 4000,
                height: 3000,
                alt: "Projet Tilff",
                projectIndex: 1
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_006-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_006-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_006-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_006-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 4000,
                height: 3000,
                alt: "Projet Tilff",
                projectIndex: 1
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_007-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_007-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_007-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_007-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 4000,
                height: 3000,
                alt: "Projet Tilff",
                projectIndex: 1
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_008-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_008-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_008-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_008-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 4000,
                height: 3000,
                alt: "Projet Tilff",
                projectIndex: 1
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_009-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_009-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_009-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_009-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 4000,
                height: 3000,
                alt: "Projet Tilff",
                projectIndex: 1
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_010-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_010-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_010-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_010-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 4000,
                height: 3000,
                alt: "Projet Tilff",
                projectIndex: 1
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_011-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_011-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_011-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_011-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 4000,
                height: 3000,
                alt: "Projet Tilff",
                projectIndex: 1
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_012-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_012-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_012-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_012-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 4000,
                height: 3000,
                alt: "Projet Tilff",
                projectIndex: 1
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_013-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_013-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_013-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_013-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 4000,
                height: 3000,
                alt: "Projet Tilff",
                projectIndex: 1
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_014-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_014-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_014-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_014-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 4000,
                height: 3000,
                alt: "Projet Tilff",
                projectIndex: 1
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_015-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_015-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_015-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_015-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 4000,
                height: 3000,
                alt: "Projet Tilff",
                projectIndex: 1
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_016-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_016-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_016-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_016-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 4000,
                height: 3000,
                alt: "Projet Tilff",
                projectIndex: 1
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_017-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_017-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_017-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_017-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 4000,
                height: 3000,
                alt: "Projet Tilff",
                projectIndex: 1
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_018-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_018-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_018-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_018-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 4000,
                height: 3000,
                alt: "Projet Tilff",
                projectIndex: 1
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_019-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_019-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_019-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_019-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 4000,
                height: 3000,
                alt: "Projet Tilff",
                projectIndex: 1
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_020-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_020-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_020-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_020-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 4000,
                height: 3000,
                alt: "Projet Tilff",
                projectIndex: 1
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_021-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_021-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_021-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_021-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 4000,
                height: 3000,
                alt: "Projet Tilff",
                projectIndex: 1
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_022-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_022-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_022-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_022-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 4000,
                height: 3000,
                alt: "Projet Tilff",
                projectIndex: 1
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_023-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_023-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_023-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_023-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 4000,
                height: 3000,
                alt: "Projet Tilff",
                projectIndex: 1
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_024-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_024-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_024-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_024-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 5827,
                height: 3890,
                alt: "Projet Tilff",
                projectIndex: 1
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_025-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_025-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_025-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_025-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 5909,
                height: 3945,
                alt: "Projet Tilff",
                projectIndex: 1
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_026-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_026-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_026-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_026-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 5737,
                height: 3830,
                alt: "Projet Tilff",
                projectIndex: 1
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_027-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_027-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_027-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_027-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 5879,
                height: 3925,
                alt: "Projet Tilff",
                projectIndex: 1
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_028-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_028-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_028-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_028-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 5924,
                height: 3955,
                alt: "Projet Tilff",
                projectIndex: 1
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_029-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_029-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_029-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_029-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 5888,
                height: 3931,
                alt: "Projet Tilff",
                projectIndex: 1
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_030-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_030-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_030-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_030-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 7233,
                height: 4827,
                alt: "Projet Tilff",
                projectIndex: 1
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_031-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_031-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_031-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_031-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 7360,
                height: 4912,
                alt: "Projet Tilff",
                projectIndex: 1
              },
              {
                src: properties.projectImagesUrl + "01/01/details/tilff_032-sm-930w.jpg",
                srcSet: [
                  properties.projectImagesUrl + "01/01/details/tilff_032-sm-530w.jpg 530w",
                  properties.projectImagesUrl + "01/01/details/tilff_032-sm-930w.jpg 930w",
                  properties.projectImagesUrl + "01/01/details/tilff_032-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 7298,
                height: 4871,
                alt: "Projet Tilff",
                projectIndex: 1
              }
        ]
    },

    //
    // PROJECT 2
    //
    {
        title: "Bureaux Tilff",
        program: "Réalisation de bureaux",
        year: "2020",
        place: "Tilff", 
        owner: "Jean Dupont",
        status: "Finalisé",
        stabilityPartner: "Stability Company",
        description: "Exercitation proident amet sit",
        projectIndex: 2,
        images: [
            {
                src: properties.assetUrl + "projects/01/02/01-02-main-1_tksja2_c_scale,w_2521.jpg",
                srcSet: [
                  properties.assetUrl + "projects/01/02/01-02-main-1_tksja2_c_scale,w_480.jpg 480w",
                  properties.assetUrl + "projects/01/02/01-02-main-1_tksja2_c_scale,w_2521.jpg 2521w",
                  properties.assetUrl + "projects/01/02/01-02-main-1_tksja2_c_scale,w_3364.jpg 3364w",
                  properties.assetUrl + "projects/01/02/01-02-main-1_tksja2_c_scale,w_3840.jpg 3840w"
                ],
                sizes: ["(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"],
                width: 3840,
                height: 5754,
                alt:"Bureaux Tilff",
                projectIndex: 2,
                useInSummary: true
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_001-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_001-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_001-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_001-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 7360,
                height: 4912,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_002-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_002-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_002-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_002-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 7256,
                height: 4843,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_003-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_003-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_003-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_003-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 7256,
                height: 4843,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_004-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_004-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_004-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_004-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 4912,
                height: 7360,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_005-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_005-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_005-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_005-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 7360,
                height: 4912,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_006-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_006-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_006-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_006-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 7360,
                height: 4912,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_007-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_007-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_007-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_007-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 7360,
                height: 4912,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_008-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_008-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_008-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_008-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 5830,
                height: 3892,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_009-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_009-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_009-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_009-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 5952,
                height: 3973,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_010-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_010-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_010-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_010-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 7360,
                height: 4912,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_011-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_011-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_011-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_011-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 7360,
                height: 4912,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_012-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_012-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_012-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_012-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 7360,
                height: 4912,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_013-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_013-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_013-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_013-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 6016,
                height: 4016,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_014-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_014-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_014-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_014-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 7172,
                height: 4787,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_015-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_015-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_015-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_015-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 5372,
                height: 3586,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_016-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_016-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_016-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_016-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 7360,
                height: 4912,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_017-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_017-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_017-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_017-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 5942,
                height: 3967,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_018-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_018-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_018-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_018-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 6016,
                height: 4016,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_019-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_019-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_019-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_019-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 6016,
                height: 4016,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_020-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_020-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_020-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_020-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 6016,
                height: 4016,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_021-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_021-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_021-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_021-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 6002,
                height: 4007,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_022-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_022-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_022-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_022-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 6016,
                height: 4016,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_023-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_023-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_023-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_023-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 5947,
                height: 3970,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_024-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_024-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_024-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_024-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 7360,
                height: 4912,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_025-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_025-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_025-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_025-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 7360,
                height: 4912,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_026-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_026-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_026-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_026-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 7360,
                height: 4912,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_027-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_027-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_027-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_027-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 7360,
                height: 4912,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_028-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_028-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_028-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_028-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 7360,
                height: 4912,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_029-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_029-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_029-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_029-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 5853,
                height: 3907,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_030-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_030-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_030-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_030-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 6016,
                height: 4016,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_031-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_031-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_031-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_031-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 7360,
                height: 4912,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_032-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_032-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_032-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_032-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 6016,
                height: 4016,
                alt: "Bureaux Tilff",
                projectIndex: 2
              },
              {
                src:  properties.assetUrl + "projects/01/02/details/bureau_HJO_033-sm-930w.jpg",
                srcSet: [
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_033-sm-530w.jpg 530w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_033-sm-930w.jpg 930w",
                   properties.assetUrl + "projects/01/02/details/bureau_HJO_033-sm-1920w.jpg 1920w"
                ],
                sizes: [
                  "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
                ],
                width: 6016,
                height: 4016,
                alt: "Bureaux Tilff",
                projectIndex: 2
              }
        ]
    },

    //
    // PROJECT 3
    //
    {
        title: "Projet rénovation",
        program: "Rénovation",
        year: "2021",
        owner: "Jean Dupont",
        status: "En cours",
        stabilityPartner: "Stability Company",
        description: "Exercitation proident amet sit laboris dolore consectetur consectetur esse cupidatat do proident exercitation. Anim duis consectetur incididunt fugiat Lorem est cupidatat elit dolore. Cillum nostrud nulla veniam mollit minim ullamco est fugiat qui anim. Aute nisi culpa qui duis consequat aliquip nulla adipisicing labore. Dolor ipsum quis eu incididunt dolore nostrud consequat. Ullamco sint ex do tempor. Eu sit aute eiusmod sunt ex ut commodo ut quis pariatur sunt.",
        projectIndex: 3,
        images: [
            {
                src: properties.assetUrl + "projects/02/02-main-1_szwrbt_c_scale,w_1228.jpg",
                srcSet: [
                properties.assetUrl + "projects/02/02-main-1_szwrbt_c_scale,w_480.jpg 480w",
                properties.assetUrl + "projects/02/02-main-1_szwrbt_c_scale,w_1228.jpg 1228w",
                properties.assetUrl + "projects/02/02-main-1_szwrbt_c_scale,w_1804.jpg 1804w",
                properties.assetUrl + "projects/02/02-main-1_szwrbt_c_scale,w_2122.jpg 2122w"
                ],
                sizes: ["(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"],
                width: 2122,
                height: 3001,
                alt:"Projet rénovation",
                projectIndex: 3,
                useInSummary: true
            },
            {
                src: properties.assetUrl + "projects/02/02-main-2_wyi4xx_c_scale,w_2036.jpg",
                srcSet: [
                properties.assetUrl + "projects/02/02-main-2_wyi4xx_c_scale,w_480.jpg 480w",
                properties.assetUrl + "projects/02/02-main-2_wyi4xx_c_scale,w_2036.jpg 2036w",
                properties.assetUrl + "projects/02/02-main-2_wyi4xx_c_scale,w_3175.jpg 3175w",
                properties.assetUrl + "projects/02/02-main-2_wyi4xx_c_scale,w_3840.jpg 3840w"
                ],
                sizes: ["(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"],
                width: 3840,
                height: 2560,
                alt:"Projet rénovation",
                projectIndex: 3,
                useInSummary: true
            },
            {
              src: properties.assetUrl + "projects/02/details/20190919_092831-sm-930w.jpg",
              srcSet: [
                properties.assetUrl + "projects/02/details/20190919_092831-sm-530w.jpg 530w",
                properties.assetUrl + "projects/02/details/20190919_092831-sm-930w.jpg 930w",
                properties.assetUrl + "projects/02/details/20190919_092831-sm-1920w.jpg 1920w"
              ],
              sizes: [
                "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
              ],
              width: 4128,
              height: 3096,
              alt: "Projet de rénovation",
              projectIndex: 3
            },
            {
              src: properties.assetUrl + "projects/02/details/IMG-20201030-WA0005-sm-930w.jpg",
              srcSet: [
                properties.assetUrl + "projects/02/details/IMG-20201030-WA0005-sm-530w.jpg 530w",
                properties.assetUrl + "projects/02/details/IMG-20201030-WA0005-sm-930w.jpg 930w",
                properties.assetUrl + "projects/02/details/IMG-20201030-WA0005-sm-1920w.jpg 1920w"
              ],
              sizes: [
                "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
              ],
              width: 1440,
              height: 1920,
              alt: "Projet de rénovation",
              projectIndex: 3
            },
            {
              src: properties.assetUrl + "projects/02/details/IMG_20210108_115308-sm-930w.jpg",
              srcSet: [
                properties.assetUrl + "projects/02/details/IMG_20210108_115308-sm-530w.jpg 530w",
                properties.assetUrl + "projects/02/details/IMG_20210108_115308-sm-930w.jpg 930w",
                properties.assetUrl + "projects/02/details/IMG_20210108_115308-sm-1920w.jpg 1920w"
              ],
              sizes: [
                "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
              ],
              width: 4000,
              height: 3000,
              alt: "Projet de rénovation",
              projectIndex: 3
            },
            {
              src: properties.assetUrl + "projects/02/details/IMG_2077-sm-930w.JPG",
              srcSet: [
                properties.assetUrl + "projects/02/details/IMG_2077-sm-530w.JPG 530w",
                properties.assetUrl + "projects/02/details/IMG_2077-sm-930w.JPG 930w",
                properties.assetUrl + "projects/02/details/IMG_2077-sm-1920w.JPG 1920w"
              ],
              sizes: [
                "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
              ],
              width: 5184,
              height: 3456,
              alt: "Projet de rénovation",
              projectIndex: 3
            },
            {
              src: properties.assetUrl + "projects/02/details/IMG_2082-sm-930w.JPG",
              srcSet: [
                properties.assetUrl + "projects/02/details/IMG_2082-sm-530w.JPG 530w",
                properties.assetUrl + "projects/02/details/IMG_2082-sm-930w.JPG 930w",
                properties.assetUrl + "projects/02/details/IMG_2082-sm-1920w.JPG 1920w"
              ],
              sizes: [
                "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
              ],
              width: 5184,
              height: 3456,
              alt: "Projet de rénovation",
              projectIndex: 3
            },
            {
              src: properties.assetUrl + "projects/02/details/IMG_2083-sm-930w.JPG",
              srcSet: [
                properties.assetUrl + "projects/02/details/IMG_2083-sm-530w.JPG 530w",
                properties.assetUrl + "projects/02/details/IMG_2083-sm-930w.JPG 930w",
                properties.assetUrl + "projects/02/details/IMG_2083-sm-1920w.JPG 1920w"
              ],
              sizes: [
                "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
              ],
              width: 5184,
              height: 3456,
              alt: "Projet de rénovation",
              projectIndex: 3
            },
            {
              src: properties.assetUrl + "projects/02/details/IMG_2084-sm-930w.JPG",
              srcSet: [
                properties.assetUrl + "projects/02/details/IMG_2084-sm-530w.JPG 530w",
                properties.assetUrl + "projects/02/details/IMG_2084-sm-930w.JPG 930w",
                properties.assetUrl + "projects/02/details/IMG_2084-sm-1920w.JPG 1920w"
              ],
              sizes: [
                "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
              ],
              width: 5184,
              height: 3456,
              alt: "Projet de rénovation",
              projectIndex: 3
            },
            {
              src: properties.assetUrl + "projects/02/details/IMG_2085-sm-930w.JPG",
              srcSet: [
                properties.assetUrl + "projects/02/details/IMG_2085-sm-530w.JPG 530w",
                properties.assetUrl + "projects/02/details/IMG_2085-sm-930w.JPG 930w",
                properties.assetUrl + "projects/02/details/IMG_2085-sm-1920w.JPG 1920w"
              ],
              sizes: [
                "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
              ],
              width: 5184,
              height: 3456,
              alt: "Projet de rénovation",
              projectIndex: 3,
              useInSummary: true
            },
            {
              src: properties.assetUrl + "projects/02/details/IMG_2086-sm-930w.JPG",
              srcSet: [
                properties.assetUrl + "projects/02/details/IMG_2086-sm-530w.JPG 530w",
                properties.assetUrl + "projects/02/details/IMG_2086-sm-930w.JPG 930w",
                properties.assetUrl + "projects/02/details/IMG_2086-sm-1920w.JPG 1920w"
              ],
              sizes: [
                "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
              ],
              width: 3456,
              height: 5184,
              alt: "Projet de rénovation",
              projectIndex: 3
            },
            {
              src: properties.assetUrl + "projects/02/details/IMG_2146-sm-930w.JPG",
              srcSet: [
                properties.assetUrl + "projects/02/details/IMG_2146-sm-530w.JPG 530w",
                properties.assetUrl + "projects/02/details/IMG_2146-sm-930w.JPG 930w",
                properties.assetUrl + "projects/02/details/IMG_2146-sm-1920w.JPG 1920w"
              ],
              sizes: [
                "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
              ],
              width: 5184,
              height: 3456,
              alt: "Projet de rénovation",
              projectIndex: 3
            },
            {
              src: properties.assetUrl + "projects/02/details/IMG_2151-sm-930w.JPG",
              srcSet: [
                properties.assetUrl + "projects/02/details/IMG_2151-sm-530w.JPG 530w",
                properties.assetUrl + "projects/02/details/IMG_2151-sm-930w.JPG 930w",
                properties.assetUrl + "projects/02/details/IMG_2151-sm-1920w.JPG 1920w"
              ],
              sizes: [
                "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
              ],
              width: 5184,
              height: 3456,
              alt: "Projet de rénovation",
              projectIndex: 3
            },
            {
              src: properties.assetUrl + "projects/02/details/IMG_2155-sm-930w.JPG",
              srcSet: [
                properties.assetUrl + "projects/02/details/IMG_2155-sm-530w.JPG 530w",
                properties.assetUrl + "projects/02/details/IMG_2155-sm-930w.JPG 930w",
                properties.assetUrl + "projects/02/details/IMG_2155-sm-1920w.JPG 1920w"
              ],
              sizes: [
                "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
              ],
              width: 5184,
              height: 3456,
              alt: "Projet de rénovation",
              projectIndex: 3
            },
            {
              src: properties.assetUrl + "projects/02/details/IMG_2156-sm-930w.JPG",
              srcSet: [
                properties.assetUrl + "projects/02/details/IMG_2156-sm-530w.JPG 530w",
                properties.assetUrl + "projects/02/details/IMG_2156-sm-930w.JPG 930w",
                properties.assetUrl + "projects/02/details/IMG_2156-sm-1920w.JPG 1920w"
              ],
              sizes: [
                "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
              ],
              width: 5184,
              height: 3456,
              alt: "Projet de rénovation",
              projectIndex: 3
            }
        ]
    },
  
    //
    // PROJECT 4
    //
    {
        title: "Annexe avec bardage",
        program: "Réalisation d'un bardage",
        year: "2021",
        owner: "Jean Dupont",
        status: "En cours",
        stabilityPartner: "Stability Company",
        description: "Exercitation proident amet sit laboris dolore consectetur consectetur esse cupidatat do proident exercitation. Anim duis consectetur incididunt fugiat Lorem est cupidatat elit dolore. Cillum nostrud nulla veniam mollit minim ullamco est fugiat qui anim. Aute nisi culpa qui duis consequat aliquip nulla adipisicing labore. Dolor ipsum quis eu incididunt dolore nostrud consequat. Ullamco sint ex do tempor. Eu sit aute eiusmod sunt ex ut commodo ut quis pariatur sunt.",
        projectIndex: 4,
        images: [
            {
                src: properties.assetUrl + "projects/03/03-main-1_gtquaj_c_scale,w_1162.jpg",
                srcSet: [
                    properties.assetUrl + "projects/03/03-main-1_gtquaj_c_scale,w_480.jpg 480w",
                    properties.assetUrl + "projects/03/03-main-1_gtquaj_c_scale,w_1162.jpg 1162w",
                    properties.assetUrl + "projects/03/03-main-1_gtquaj_c_scale,w_1302.jpg 1302w"
                ],
                sizes: ["(min-width: 480px) 33.3vw,(min-width: 1302px) 33.3vw,100vw"],
                width: 1302,
                height: 739,
                alt:"Annexe avec bardage",
                projectIndex: 4,
                useInSummary: true
            },
            {
                src: properties.assetUrl + "projects/03/03-main-2_sqwmyk_c_scale,w_1518.jpg",
                srcSet: [
                    properties.assetUrl + "projects/03/03-main-2_sqwmyk_c_scale,w_480.jpg 480w",
                    properties.assetUrl + "projects/03/03-main-2_sqwmyk_c_scale,w_1518.jpg 1518w",
                    properties.assetUrl + "projects/03/03-main-2_sqwmyk_c_scale,w_2420.jpg 2420w",
                    properties.assetUrl + "projects/03/03-main-2_sqwmyk_c_scale,w_2456.jpg 2456w"
                ],
                sizes: ["(min-width: 480px) 33.3vw,(min-width: 1302px) 33.3vw,100vw"],
                width: 2456,
                height: 1320,
                alt:"Annexe avec bardage",
                projectIndex: 4,
                useInSummary: true
            },
            {
              src: properties.assetUrl + "projects/03/details/IMG_20201104_080100-sm-930w.jpg",
              srcSet: [
                properties.assetUrl + "projects/03/details/IMG_20201104_080100-sm-530w.jpg 530w",
                properties.assetUrl + "projects/03/details/IMG_20201104_080100-sm-930w.jpg 930w",
                properties.assetUrl + "projects/03/details/IMG_20201104_080100-sm-1920w.jpg 1920w"
              ],
              sizes: [
                "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
              ],
              width: 3264,
              height: 1568,
              alt: "Annexe en bardage",
              projectIndex: 4
            },
            {
              src: properties.assetUrl + "projects/03/details/IMG_20201104_080126-sm-930w.jpg",
              srcSet: [
                properties.assetUrl + "projects/03/details/IMG_20201104_080126-sm-530w.jpg 530w",
                properties.assetUrl + "projects/03/details/IMG_20201104_080126-sm-930w.jpg 930w",
                properties.assetUrl + "projects/03/details/IMG_20201104_080126-sm-1920w.jpg 1920w"
              ],
              sizes: [
                "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
              ],
              width: 2274,
              height: 1340,
              alt: "Annexe en bardage",
              projectIndex: 4
            },
            {
              src: properties.assetUrl + "projects/03/details/IMG_20201104_080142-sm-930w.jpg",
              srcSet: [
                properties.assetUrl + "projects/03/details/IMG_20201104_080142-sm-530w.jpg 530w",
                properties.assetUrl + "projects/03/details/IMG_20201104_080142-sm-930w.jpg 930w",
                properties.assetUrl + "projects/03/details/IMG_20201104_080142-sm-1920w.jpg 1920w"
              ],
              sizes: [
                "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
              ],
              width: 1568,
              height: 3264,
              alt: "Annexe en bardage",
              projectIndex: 4
            },
            {
              src: properties.assetUrl + "projects/03/details/IMG_20201104_080206-sm-930w.jpg",
              srcSet: [
                properties.assetUrl + "projects/03/details/IMG_20201104_080206-sm-530w.jpg 530w",
                properties.assetUrl + "projects/03/details/IMG_20201104_080206-sm-930w.jpg 930w",
                properties.assetUrl + "projects/03/details/IMG_20201104_080206-sm-1920w.jpg 1920w"
              ],
              sizes: [
                "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
              ],
              width: 2916,
              height: 1568,
              alt: "Annexe en bardage",
              projectIndex: 4
            },
            {
              src: properties.assetUrl + "projects/03/details/IMG_20201104_080216-sm-930w.jpg",
              srcSet: [
                properties.assetUrl + "projects/03/details/IMG_20201104_080216-sm-530w.jpg 530w",
                properties.assetUrl + "projects/03/details/IMG_20201104_080216-sm-930w.jpg 930w",
                properties.assetUrl + "projects/03/details/IMG_20201104_080216-sm-1920w.jpg 1920w"
              ],
              sizes: [
                "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
              ],
              width: 3264,
              height: 1568,
              alt: "Annexe en bardage",
              projectIndex: 4
            },
            {
              src: properties.assetUrl + "projects/03/details/IMG_20201212_203146-sm-930w.jpg",
              srcSet: [
                properties.assetUrl + "projects/03/details/IMG_20201212_203146-sm-530w.jpg 530w",
                properties.assetUrl + "projects/03/details/IMG_20201212_203146-sm-930w.jpg 930w",
                properties.assetUrl + "projects/03/details/IMG_20201212_203146-sm-1920w.jpg 1920w"
              ],
              sizes: [
                "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
              ],
              width: 3264,
              height: 1568,
              alt: "Annexe en bardage",
              projectIndex: 4
            }
        ]
    },

    //
    // PROJECT 5
    //
    {
      title: "Annexe terasse",
      program: "Réalisation d'une terasse",
      year: "2021",
      place: "Liège", 
      owner: "Lucie Dupont",
      status: "En cours",
      stabilityPartner: "Stability Company",
      description: "Exercitation proident amet sit laboris dolore.",
      projectIndex: 5,
      images: [
        {
          src: properties.assetUrl + "projects/04/IMG_2385-sm-930w.JPG",
          srcSet: [
            properties.assetUrl + "projects/04/IMG_2385-sm-530w.JPG 530w",
            properties.assetUrl + "projects/04/IMG_2385-sm-930w.JPG 930w",
            properties.assetUrl + "projects/04/IMG_2385-sm-1920w.JPG 1920w"
          ],
          sizes: [
            "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
          ],
          width: 5184,
          height: 3456,
          alt: "Annexe terasse",
          projectIndex: 5,
          useInSummary: true
        },
        {
          src: properties.assetUrl + "projects/04/IMG_2386-sm-930w.JPG",
          srcSet: [
            properties.assetUrl + "projects/04/IMG_2386-sm-530w.JPG 530w",
            properties.assetUrl + "projects/04/IMG_2386-sm-930w.JPG 930w",
            properties.assetUrl + "projects/04/IMG_2386-sm-1920w.JPG 1920w"
          ],
          sizes: [
            "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
          ],
          width: 5184,
          height: 3456,
          alt: "Annexe terasse",
          projectIndex: 5
        },
        {
          src: properties.assetUrl + "projects/04/IMG_2387-sm-930w.JPG",
          srcSet: [
            properties.assetUrl + "projects/04/IMG_2387-sm-530w.JPG 530w",
            properties.assetUrl + "projects/04/IMG_2387-sm-930w.JPG 930w",
            properties.assetUrl + "projects/04/IMG_2387-sm-1920w.JPG 1920w"
          ],
          sizes: [
            "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
          ],
          width: 5184,
          height: 3456,
          alt: "Annexe terasse",
          projectIndex: 5
        }
      ]
    },

    //
    // PROJECT 6
    //
    {
        title: "Projet RVEP",
        program: "Sample",
        year: "2019",
        place: "Bruxelles", 
        owner: "Claire Dupont",
        status: "Finalisé",
        stabilityPartner: "Stability Company",
        description: "Exercitation proident amet sit laboris dolore consectetur consectetur esse cupidatat do proident exercitation. Anim duis consectetur incididunt fugiat Lorem est cupidatat elit dolore. Cillum nostrud nulla veniam mollit minim ullamco est fugiat qui anim. Aute nisi culpa qui duis consequat aliquip nulla adipisicing labore. Dolor ipsum quis eu incididunt dolore nostrud consequat. Ullamco sint ex do tempor. Eu sit aute eiusmod sunt ex ut commodo ut quis pariatur sunt.",
        projectIndex: 6,
        compare: [3,1],
        images: [
            {
              src: properties.projectImagesUrl + "05/vue-1-permis-sm-930w.jpg",
              srcSet: [
                properties.projectImagesUrl + "05/vue-1-permis-sm-530w.jpg 530w",
                properties.projectImagesUrl + "05/vue-1-permis-sm-930w.jpg 930w",
                properties.projectImagesUrl + "05/vue-1-permis-sm-1920w.jpg 1920w"
              ],
              sizes: [
                "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
              ],
              width: 1920,
              height: 1080,
              alt: "Projet RVEP",
              projectIndex: 6,
              useInSummary: true
            },
            {
              src: properties.projectImagesUrl + "05/vue-apres-1-sm-930w.jpg",
              srcSet: [
                properties.projectImagesUrl + "05/vue-apres-1-sm-530w.jpg 530w",
                properties.projectImagesUrl + "05/vue-apres-1-sm-930w.jpg 930w",
                properties.projectImagesUrl + "05/vue-apres-1-sm-1920w.jpg 1920w"
              ],
              sizes: [
                "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
              ],
              width: 1920,
              height: 1080,
              alt: "Projet RVEP",
              projectIndex: 6,
              useInSummary: true
            },
            {
              src: properties.projectImagesUrl + "05/vue-apres-2-sm-930w.jpg",
              srcSet: [
                properties.projectImagesUrl + "05/vue-apres-2-sm-530w.jpg 530w",
                properties.projectImagesUrl + "05/vue-apres-2-sm-930w.jpg 930w",
                properties.projectImagesUrl + "05/vue-apres-2-sm-1920w.jpg 1920w"
              ],
              sizes: [
                "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
              ],
              width: 1920,
              height: 1080,
              alt: "Projet RVEP",
              projectIndex: 6
            },
            {
              src: properties.projectImagesUrl + "05/vue-avant-sm-930w.jpg",
              srcSet: [
                properties.projectImagesUrl + "05/vue-avant-sm-530w.jpg 530w",
                properties.projectImagesUrl + "05/vue-avant-sm-930w.jpg 930w",
                properties.projectImagesUrl + "05/vue-avant-sm-1920w.jpg 1920w"
              ],
              sizes: [
                "(min-width: 480px) 33.3vw,(min-width: 1920px) 33.3vw,100vw"
              ],
              width: 1920,
              height: 1080,
              alt: "Projet RVEP",
              projectIndex: 6
            }
        ]
    },
];

let orderedProjectSummaryImages = [];
export const projectSummaryImages = () => {

  if (orderedProjectSummaryImages.length > 1)
    return orderedProjectSummaryImages;

    const allImages = [];
    projects.forEach(project => {
        project.images.forEach(image => {
            allImages.push(image);
        })
    });
    let summaryImages = allImages.filter(image => image.useInSummary === true);
    orderedProjectSummaryImages = shuffle(summaryImages);

    return orderedProjectSummaryImages;
}

function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export const getProject = (projectIndex) => {
    if (projectIndex < 0 || projectIndex > (projects.length-1)) {
        projectIndex = 0;
    }

    return projects[projectIndex];
}